.table {
  display: flex;
  flex-direction: column;
  gap: 1px;
  background: var(--black-color-30);
  padding: 1px;
  font-size: 10.5pt;
  border-radius: 8px;
}

.table:not(:first-child) {
  margin-top: 20px;
}

@media (prefers-color-scheme: dark) {
  .table {
    background: var(--black-color-800);
  }
}

@media (max-width: 820px) {
  .table {
    gap: 20px 0;
    background: transparent;
  }
}

.table .cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-word;
  text-align: center;
}

.table .cell button {
  flex-shrink: 0;
}

.table .cell button > svg {
  opacity: 0.7;
  transition: 0.21s;
}

.table .cell button:hover > svg {
  opacity: 1;
}

.table > .row > .cell {
  padding: 12px;
  flex: 1;
  background: #fff;
}

@media (prefers-color-scheme: dark) {
  .table > .row > .cell {
    background: var(--black-color-900);
  }
}

@media (max-width: 1200px) {
  .table > .row > .cell {
    padding: 7px 12px;
  }
}

.table > .row {
  display: flex;
  gap: 1px;
}

@media (max-width: 820px) {
  .table > .row {
    display: none;
  }
}

.table > .row:not(:first-child) > .cell {
  user-select: text;
}

.table > .row[class*='_isHoverable'] {
  cursor: pointer;
}

.table > .row[class*='_isHoverable']:hover > .cell {
  background: var(--black-color-10);
}

@media (prefers-color-scheme: dark) {
  .table > .row[class*='_isHoverable']:hover > .cell {
    background: var(--black-color-800);
  }
}

.table > .row.table-header {
  font-weight: bold;
  font-size: 12pt;
  position: sticky;
  text-transform: uppercase;
  z-index: 1;
}

/* Mobile */

.table > .mobile-cell {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  background: var(--black-color-10);
  margin: 0;
  border-radius: 8px;
}

@media (min-width: 820px) {
  .table > .mobile-cell {
    display: none;
  }
}

@media (prefers-color-scheme: dark) {
  .table > .mobile-cell {
    background: var(--black-color-900);
  }
}

.table > .mobile-cell > [class='row']:last-child {
  border-bottom: none;
}

@media (prefers-color-scheme: dark) {
  .table > .mobile-cell > [class='row'] {
    border-bottom-color: var(--black-color-700) !important;
  }
}
