/* Data rows */

.data-rows:not(:first-child) {
  margin-top: 20px;
}

.data-rows > .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px 10px;
  padding: 15px 0;
  border-bottom: 1px dashed var(--black-color-30);
  user-select: text;
}

@media (prefers-color-scheme: dark) {
  .data-rows > .row {
    border-bottom-color: var(--black-color-900);
  }
}

.data-rows > .row > div {
  word-break: break-word;
}

.data-rows > .row > div:first-child {
  font-weight: bold;
}

/* Loader container */

.loader-container {
  display: flex;
  justify-content: center;
  padding: 50px;
}

/* Flexes */

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-aic {
  align-items: center;
}

.flex-jce {
  justify-content: flex-end;
}

.flex-jcsb {
  justify-content: space-between;
}

.flex-jcc {
  justify-content: center;
}

/* Raw tables */
.raw-table-container {
  overflow: scroll;
  border-radius: 8px;
  overscroll-behavior: auto;
}

.raw-table-container:not(:first-child) {
  margin-top: 20px;
}

.raw-table-container > table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  min-width: 840px;
}

.raw-table-container > table th,
.raw-table-container > table td {
  border: 1px solid var(--black-color-30);
  padding: 7px 12px;
  font-size: 11pt;
  text-align: center;
}

.raw-table-container > table th {
  text-transform: uppercase;
}

@media (prefers-color-scheme: dark) {
  .raw-table-container > table th,
  .raw-table-container > table td {
    background: var(--black-color-900);
    border-color: var(--black-color-800)
  }
}
