.auth-page {
  max-width: 450px !important;
  margin-top: 100px !important
}

.auth-page > h1 {
  font-size: 16pt
}

.auth-page > form {
  margin-top: 20px;
}
