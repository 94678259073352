.bookings-page {
  max-width: unset !important;
}

.booking-window {
  gap: 20px 50px
}

.booking-window > div {
  flex: 1;
  min-width: 300px;
}
