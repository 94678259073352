.quizItems {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 50px
}

.quizItem {
  border: 1px solid #999;
  border-radius: 20px;
  padding: 20px;
  width: 300px;
  user-select: text;
}

.question {
  font-weight: bold;
  font-size: 14pt;
}

.question:not(:first-child) {
  margin-top: 50px;
}

.question-answer {
  margin-top: 3px;
  color: #999;
  /* font-size: 10.5pt; */
}

.question-comment {
  margin-top: 10px;
  line-height: 1.5em;
}

.question-bubbles {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 0 12px;
  width: 100%;
  margin-top: 30px
}

.question-bubbles > div.question-bubble {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #c5cddc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  user-select: none;
  transition: 0.1s;
  margin-top: 12px;
}

.question-bubbles > div.question-bubble[class*="_isActive"] {
  border-color: #cee0ff;
  background: #cee0ff;
  color: #000
}

.question-bubbles > div.bubble-text {
  font-style: italic;
  margin-top: 3px;
  font-size: 10.5pt
}
