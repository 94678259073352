:root {
  --light-green: #80ff86
}

* {
  margin: 0;
  appearance: none;
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
}

::selection {
  background: var(--accent-color-20);
}

@media (prefers-color-scheme: dark) {
  ::selection {
    background: var(--accent-color-400);
  }
}

html,
body {
  margin: 0;
}

body {
  user-select: none;
  font: 1em -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

@media (prefers-color-scheme: dark) {
  body {
    background: var(--black-color-1000);
    color: #fff;
  }
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

hr {
  display: block;
  width: 100%;
  height: 1px;
  border: none;
  margin: 30px 0;
  background: var(--black-color-30);
}

@media (prefers-color-scheme: dark) {
  hr {
    background: var(--black-color-800);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  user-select: text;
}

ul {
  padding-left: 1em;
}

ol {
  padding: 0;
  list-style-position: inside;
}

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child),
h4:not(:first-child),
h5:not(:first-child),
h6:not(:first-child),
p:not(:first-child),
ol:not(:first-child),
ul:not(:first-child) {
  margin-top: 20px;
}

ol > li:not(:first-child),
ul > li:not(:first-child) {
  margin-top: 10px;
}

a {
  text-decoration: none;
  color: var(--accent-color-100);
  transition: 0.21s;
}

@media (prefers-color-scheme: dark) {
  a {
    color: var(--accent-color-50);
  }
}

a:hover {
  color: var(--accent-color-80);
}

@media (prefers-color-scheme: dark) {
  a:hover {
    color: var(--accent-color-60);
  }
}

img,
svg,
audio,
video,
iframe {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
}
